import BottomMenuItem from '@/components/BottomMenuItem'
import Layout, { Stack } from '@/components/Layout'
import { useFeatureToggle } from '@/context/featureToggles'
import { BottomNavigationActiveTab, useNavigation } from '@/context/navigation'
import * as styles from './style'

export interface Props {
  activeTab: BottomNavigationActiveTab
}

const BottomNavigation = ({ activeTab }: Props): JSX.Element => {
  const canSeeClassNotes = useFeatureToggle('eng_class_notes')
  const showHelenTab = useFeatureToggle('helen_tab')

  const {
    state: {
      navigation: { learnUrl },
    },
  } = useNavigation()

  return (
    <Layout className={styles.wrapper}>
      <Stack x alignItems="center" justifyContent="space-between">
        <BottomMenuItem active={activeTab === 'home'} icon="home" label="Home" link="/" />
        <BottomMenuItem active={activeTab === 'learn'} icon="school" label="Learn" link={learnUrl} />
        {showHelenTab && <BottomMenuItem active={activeTab === 'helen'} icon="chat" label="Helen" link="/helen" />}
        {canSeeClassNotes ? (
          <BottomMenuItem active={activeTab === 'profile'} icon="user" label="Profile" link="/notes" />
        ) : (
          <BottomMenuItem active={activeTab === 'profile'} icon="user" label="Profile" link="/account-settings" />
        )}
      </Stack>
    </Layout>
  )
}
export default BottomNavigation
