import * as React from 'react'

interface NavigationState {
  learnUrl: string
}

interface SearchBoxState {
  active: boolean
  open: boolean
  query?: string | undefined
}

export type BottomNavigationActiveTab = 'home' | 'learn' | 'profile' | 'helen'

type Dispatch = (action: Action) => void
type ProviderProps = { children: React.ReactNode }
type Action =
  | { type: 'SET_LEARN_URL'; payload: string }
  | { type: 'SET_SEARCH_ACTIVE'; payload: boolean }
  | { type: 'SET_SEARCH_OPEN'; payload: boolean }
  | { type: 'SET_SEARCH_QUERY'; payload: string | undefined }
type State = { navigation: NavigationState; searchBox: SearchBoxState }
interface ContextInterface {
  state: State
  dispatch: Dispatch
}

const Context = React.createContext<ContextInterface | undefined>(undefined)

const initialState: State = {
  navigation: { learnUrl: '/schedule/browse' },
  searchBox: { active: false, open: false, query: '' },
}

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'SET_LEARN_URL': {
      return { ...state, navigation: { ...state.navigation, learnUrl: action.payload } }
    }
    case 'SET_SEARCH_ACTIVE': {
      return { ...state, searchBox: { ...state.searchBox, active: action.payload } }
    }
    case 'SET_SEARCH_OPEN': {
      return { ...state, searchBox: { ...state.searchBox, open: action.payload } }
    }
    case 'SET_SEARCH_QUERY': {
      return { ...state, searchBox: { ...state.searchBox, query: action.payload } }
    }

    default: {
      return {
        ...state,
      }
    }
  }
}

function Provider({ children }: ProviderProps): JSX.Element {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const value = { state, dispatch }
  return <Context.Provider value={value}>{children}</Context.Provider>
}
const NavigationProvider = Provider

function useNavigation(): ContextInterface {
  const context = React.useContext(Context)
  if (context === undefined) {
    throw new Error('useNavigation must be used within a Provider')
  }
  return context
}
export { NavigationProvider, useNavigation }
